import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useProfile from '../../Hooks/useProfile';
import useSetting from '../../Hooks/useSetting';

const Header = () => {

    const generalSetting = useSetting();
    const { id } = useProfile();
    var adminPath = process.env.REACT_APP_ADMIN_ROUTE_PREFIX
    const customer_prefix = process.env.REACT_APP_CUSTOMER_ROUTE_PREFIX;
    return (
        <header>
            <div className="bg-light-blue">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex py-2">
                            <a href={`tel:${generalSetting?.phone}`} role="button" className="no-effect px-2 border-end border-orange border-2 d-flex align-items-center">
                                <i className="fa-solid fa-phone fs-2 text-orange"></i>
                                <span className="mx-2 text-700 fs--1 d-none d-md-inline-block">{generalSetting?.phone}</span>
                            </a>
                            <a href={`mailto:${generalSetting?.email}`} role="button" className="no-effect px-3 d-flex align-items-center">
                                <i className="fa-regular fa-envelope fs-2 text-orange"></i>
                                <span className="mx-2 text-700 fs--1 d-none d-md-inline-block">{generalSetting?.email}</span>
                            </a>
                        </div>
                        <div className="fs-1 d-flex py-2">
                            <a className="text-800 border-end border-2 px-3" href={generalSetting?.facebook_link} target="__blank">
                                <i className="fa-brands fa-facebook-f"></i>
                            </a>
                            <a className="text-800 border-end border-2 px-3" href={generalSetting?.instagram_link} target="__blank">
                                <i className="fa-brands fa-instagram"></i>
                            </a>
                            <a className="text-800 border-end border-2 px-3" href={generalSetting?.linkedin_link} target="__blank">
                                <i className="fa-brands fa-linkedin-in"></i>
                            </a>
                            <a className="text-800 px-3" href={generalSetting?.twitter_link} target="__blank">
                                <i className="fa-brands fa-twitter"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Navbar bg="light" expand="lg">
                <Container className="py-1">
                    <Navbar.Brand as={Link} to="/home" className="main">
                        <img className="me-lg-5 me-sm-1 logo-orange" src={generalSetting.logo && (generalSetting.path + generalSetting.logo)} alt={generalSetting.application_name} style={{ maxWidth: 180, maxHeight: 45 }} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to="/home" className="text-dark mx-sm-0 mx-lg-2">Home</Nav.Link>
                            <Nav.Link as={Link} to="/about" className="text-dark mx-sm-0 mx-lg-2">About Us</Nav.Link>
                            <Nav.Link as={Link} to="/our-services" className="text-dark mx-sm-0 mx-lg-2">Our Services</Nav.Link>
                            <Nav.Link as={Link} to="/contact-us" className="text-dark mx-sm-0 mx-lg-2">Contact Us</Nav.Link>
                        </Nav>
                        <Nav>
                            <Nav.Link as={Link} to={`${customer_prefix}/home`} className="text-white bg-orange px-4 rounded-2 mx-sm-0 mx-lg-1">{id ? "Dashboard" : "Login"}</Nav.Link>
                            <Nav.Link as={Link} to={`${adminPath}/dashboard`} className="text-white bg-orange px-4 rounded-2 mx-sm-0 mx-lg-1">{id ? "Dashboard" : "Admin"}</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    )
}

export default Header