import { BrowserRouter } from "react-router-dom";
import AllRoutes from "./routes/AllRoutes";
import 'react-toastify/dist/ReactToastify.css';
// import "./assets/css/theme.min.css";
// import "./assets/css/custom.css";
// import "./assets/plugin/fontawesome/css/all.min.css";


const App = () => {
  const basename = document.querySelector('base')?.getAttribute('href') ?? '/'
  return (
    <>
      <BrowserRouter basename={basename}>
        <AllRoutes />
      </BrowserRouter>
    </>
  );
};

export default App;