import React, { useState, useEffect, useCallback, createContext } from "react";
import AxiosHelper from '../Helpers/AxiosHelper'
import setting from "../constant/setting"


export const GeneralSettings = createContext(setting)

const GeneralSettingsProvider = (props) => {
    const [generalSetting, setGeneralSetting] = useState(setting)
    const getData = useCallback(async () => {
        const { data } = await AxiosHelper.getData(`/`);
        if (data?.status === true) {
            setGeneralSetting(data?.data)
        }
    }, [])

    useEffect(() => { getData() }, [getData])

    // Update Data while runing
    generalSetting.updateData = async () => {
        const { data } = await AxiosHelper.getData(`/`);
        if (data?.status === true) {
            setGeneralSetting(data?.data)
        }
    }

    return (
        <GeneralSettings.Provider value={generalSetting}>
            {props.children}
        </GeneralSettings.Provider>
    )
}

export default GeneralSettingsProvider