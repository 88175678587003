import { Link } from 'react-router-dom';
import useSetting from "../../Hooks/useSetting";

const Footer = () => {

    const generalSetting = useSetting();
    return (
        <footer className='border-bottom border-orange'>
            <div className="bg-gray">
                <div className="container-lg">
                    <div className="row">
                        <div className="col-md-4 bg-black">
                            <div className="d-flex flex-column min-h-250">
                                <div className="w-100 flex-4 d-flex align-items-center">
                                    <div>
                                        <img className="ms-3 logo-white" src={generalSetting.logo && (generalSetting.path + generalSetting.logo)} alt={generalSetting.application_name} style={{ maxWidth: 220, maxHeight: 45 }} />
                                        <h5 className="fs--1 text-white text-justify ms-3 mt-3"> {generalSetting.slogan}</h5>
                                    </div>
                                </div>
                                <div className="w-100 flex-1">
                                    <h5 className='text-white ms-3'>Follow Us</h5>
                                    <div className="fs-1 d-flex py-2">
                                        <a className="text-white border-end border-2 px-3" href={generalSetting?.facebook_link} target="__blank">
                                            <i className="fa-brands fa-facebook-f"></i>
                                        </a>
                                        <a className="text-white border-end border-2 px-3" href={generalSetting?.instagram_link} target="__blank">
                                            <i className="fa-brands fa-instagram"></i>
                                        </a>
                                        <a className="text-white border-end border-2 px-3" href={generalSetting?.linkedin_link} target="__blank">
                                            <i className="fa-brands fa-linkedin-in"></i>
                                        </a>
                                        <a className="text-white px-3" href={generalSetting?.twitter_link} target="__blank">
                                            <i className="fa-brands fa-twitter"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-4 px-lg-5 my-lg-4 my-2">
                                    <h4 className="text-white mb-3">Main Menu</h4>
                                    <ul className="list-group list-style-none d-grid gap-2">
                                        <li><Link className="text-200 fs--1" to="/home">Home</Link></li>
                                        <li><Link className="text-200 fs--1" to="/about">About Us</Link></li>
                                        <li><Link className="text-200 fs--1" to="/our-services">Our Services</Link></li>
                                        <li><Link className="text-200 fs--1" to="/contact-us">Contact Us</Link></li>
                                    </ul>
                                </div>
                                <div className="col-md-4 px-lg-5 my-lg-4 my-2">
                                    <h4 className="text-white mb-3">Useful Links</h4>
                                    <ul className="list-group list-style-none d-grid gap-2">
                                        <li><Link className="text-200 fs--1" to="/terms-conditions">Terms Conditions</Link></li>
                                        <li><Link className="text-200 fs--1" to="/delivery-information">Delivery Information</Link></li>
                                        <li><Link className="text-200 fs--1" to="/returns-exchanges">Returns Exchanges</Link></li>
                                        <li><Link className="text-200 fs--1" to="/privacy-policy">Privacy Policy</Link></li>
                                    </ul>
                                </div>
                                <div className="col-md-4 my-lg-4 my-2">
                                    <h4 className="text-white mb-3">Get In Touch</h4>
                                    <ul className="list-group list-style-none">
                                        <li className='d-flex justify-content-start align-items-center text-white mb-3'>
                                            <i className="text-orange fa-solid fa-phone me-3"></i>
                                            <a href={`tel:${generalSetting?.phone}`} className='my-0 fs--1 text-white no-effect'>{generalSetting?.phone}</a>
                                        </li>
                                        <li className='d-flex justify-content-start align-items-center text-white mb-3'>
                                            <i className="text-orange fa-regular fa-envelope me-3"></i>
                                            <a href={`mailto:${generalSetting?.email}`} className='my-0 fs--1 text-white no-effect'>{generalSetting?.email}</a>
                                        </li>
                                        <li className='d-flex justify-content-start align-items-center text-white mb-3'>
                                            <i className="text-orange fa-solid fa-location-pin me-3"></i>
                                            <p className='my-0 fs--1'>{generalSetting?.address}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white">
                <div className="container-lg">
                    <div className="row align-items-center">
                        <div className="col-md-4 bg-orange p-2 ps-4 text-white d-flex">
                            <i className="fa-solid fa-phone fa-2x my-auto"></i>
                            <div className="px-3">
                                <span className='d-block'>Call - Or - SMS</span>
                                <a className="fw-bold text-white no-effect" href={`tel:${generalSetting?.phone}`}>{generalSetting?.phone}</a>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-7 py-auto">
                                    <p className="text-800 my-0 fs--1 text-center text-md-end">{generalSetting?.copyright}</p>
                                </div>
                                <div className="col-md-5 d-flex align-items-center justify-content-center justify-content-md-end">
                                    <Link to="/privacy-policy" className="text-800 fw-bold fs--1">Privacy Policy</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer