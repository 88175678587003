import { Routes, Route, Outlet } from "react-router-dom";
import { lazy, Suspense } from "react";
import Loader from "../Admin/Component/Loader";
import Error404 from "../Main/Pages/Error404";

const customer_prefix = process.env.REACT_APP_CUSTOMER_ROUTE_PREFIX;
const OpenCustomer = lazy(() => import("../HOC/OpenCustomer"));
const CustomerLayout = lazy(() => import("../HOC/CustomerLayout"));
const Login = lazy(() => import("../User/Auth/Login"));
const ForgetPassword = lazy(() => import("../User/Auth/ForgetPassword"));
const ResetPassword = lazy(() => import("../User/Auth/ResetPassword"));
const ProfileDetails = lazy(() => import("../User/Pages/Profile/ProfileDetails"));
const Logout = lazy(() => import("../User/Auth/Logout"));
const ChangePass = lazy(() => import("../User/Pages/Profile/ChangePass"));
// const WishList = lazy(() => import("../User/Pages/Profile/WishList"));
const MyLedger = lazy(() => import("../User/Pages/Profile/MyLedger"));
// const GSTInvoice = lazy(() => import("../User/Pages/Profile/GSTInvoice"));
const GSTInvoicePrint = lazy(() => import("../User/Pages/Profile/GSTInvoicePrint"));
const MyNotifications = lazy(() => import("../User/Pages/Profile/MyNotifications"));
const Tickets = lazy(() => import("../User/Pages/Profile/Tickets"));
const ShoppingCart = lazy(() => import("../User/Pages/Profile/ShoppingCart"));
const Checkout = lazy(() => import("../User/Pages/Profile/Checkout"));
const MyOrders = lazy(() => import("../User/Pages/Profile/MyOrders"));
const Dashboard = lazy(() => import("../User/Pages/Dashboard"));
const PaymentsEnquiries = lazy(() => import("../User/Pages/PaymentsEnquiries"));

const ProductList = lazy(() => import("../User/Pages/ProductList"));
const ProductDetails = lazy(() => import("../User/Pages/ProductDetails"));

export default function CustomerRoutes() {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route exact path={customer_prefix} element={<OpenCustomer current={<Outlet />} />}>
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgetPassword />} />
            <Route path="reset-password/:token" element={<ResetPassword />} />
          </Route>

          <Route path={customer_prefix} element={<CustomerLayout current={<Outlet />} />}>
            {/* <Route path='' element={<Profile><Outlet /></Profile>}> */}
            <Route index element={<Dashboard />} />
            <Route path="home" element={<Dashboard />} />
            <Route path="profile" element={<ProfileDetails />} />
            <Route path="change-password" element={<ChangePass />} />
            {/* <Route path="wishlist" element={<WishList />} /> */}
            <Route path="ledger" element={<MyLedger />} />
            {/* <Route path="invoice" element={<GSTInvoice />} /> */}
            <Route path="invoice/:slug" element={<GSTInvoicePrint />} />
            <Route path="create-order" element={<ShoppingCart />} />
            <Route path="notifications" element={<MyNotifications />} />
            <Route path="tickets" element={<Tickets />} />
            <Route path="payments-enquiries" element={<PaymentsEnquiries />} />
            <Route path="my-orders" element={<MyOrders />} />
            <Route path="checkout" element={<Checkout />} />
            {/* </Route> */}

            <Route path="products" element={<ProductList />} />
            <Route path="products/:slug" element={<ProductDetails />} />
            <Route path="logout" element={<Logout />} />
          </Route>
          <Route path={`${customer_prefix}/*`} element={<Error404 />} />
          <Route path="*" element={<></>} />
        </Routes>
      </Suspense>
    </>
  );
}
