const setting = {
    favicon: "",
    logo: "",
    application_name: "",
    copyright: "",
    meta_title: "",
    meta_keyword: "",
    meta_description: "",
    address: "",
    email: "",
    phone: "",
    email_from: "",
    smtp_host: "",
    smtp_port: "",
    smtp_user: "",
    smtp_pass: "",
    facebook_link: "",
    twitter_link: "",
    youtube_link: "",
    linkedin_link: "",
    instagram_link: "",
    sms_url: "",
    sms_sender: "",
    sms_password: "",
    default_impression_screen_printing: "",
    cost_per_card_screen_printing: "",
    butter_charges_screen_printing: "",
    incremental_butter_charges_screen_printing: "",
    single_impression_minimum_amount_screen_printing: "",
    regular_min_charges_single_impression_offset_printing: "",
    big_min_charges_single_impression_offset_printing: "",
    min_increase_cost_single_impression_offset_printing: ""
};
export default setting;